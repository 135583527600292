import Fuse from 'fuse.js'
import { useState } from 'react'
import type { DataFuzzySearch, OptionsFuzzySearch } from './FuzzySearch.interface'

interface UseFuzzySearchResult<T> {
  searchResults: DataFuzzySearch<T>
  fuzzySearchQuery: (query: string) => void
}

export const useFuzzySearch = <T>(
  dataFuzzySearch: DataFuzzySearch<T>,
  optionsFuzzySearch: OptionsFuzzySearch<T>,
): UseFuzzySearchResult<T> => {
  const [searchResults, setSearchResults] = useState<DataFuzzySearch<T>>([])

  const fuzzySearchQuery = (query: string) => {
    const fuse = new Fuse(dataFuzzySearch, optionsFuzzySearch)
    const results = fuse.search(query)
    setSearchResults(results.map((result) => result.item))
  }

  return {
    searchResults,
    fuzzySearchQuery,
  }
}
