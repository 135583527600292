import { graphql } from '../gql'

export const allMembersFragment = graphql(/* GraphQL */ `
  fragment AllMembers on RootQueryToMemberConnection {
    __typename
    nodes {
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      memberFields {
        memberAlternativeImage {
          sourceUrl(size: MEDIUM_LARGE)
          altText
        }
      }
      title
      link
      id
      memberTypes {
        nodes {
          slug
        }
      }
    }
  }
`)
