import React from 'react'
import type { TFooterLinksContainerProps } from './interface'

const FooterLinksContainer = (props: TFooterLinksContainerProps) => {
  const { children } = props

  return (
    <div {...props} tw="flex gap-4">
      {children}
    </div>
  )
}

export default FooterLinksContainer
