import woodsLogoBlue from 'nextjs-app/public/shared-assets/images/Woods_logo_blue.png'
import woodsLogoWhite from 'nextjs-app/public/shared-assets/images/Woods_logo_W.png'

export const headerImageVariants = {
  white: woodsLogoWhite,
  colored: woodsLogoBlue,
  half: woodsLogoBlue,
}

export const headerIconVariants = {
  white: 'BurgerMenuWhite',
  colored: 'BurgerMenuBlue',
  half: 'BurgerMenuWhite',
}
