import tw, { css, styled } from 'twin.macro'
import { AnglesCSS } from '../../illustrations/Angles'
import { ArchCSS } from '../../illustrations/Arch'
import { BranchesCSS } from '../../illustrations/Branches'
import { GearCSS } from '../../illustrations/Gear'
import { LaurelCSS } from '../../illustrations/Laurel'
import type { StyledMenuProps, TPatternVariants } from './interface'

export const StyledMenu = styled.div<StyledMenuProps>`
  ${tw`flex flex-col justify-center h-screen text-left p-4 absolute top-0 right-0 transition duration-300 ease-in-out`}
  transform: ${({ openBurgerMenu }) => (openBurgerMenu ? 'translateX(0)' : 'translateX(100%)')};
  background: #10163b;
  ${tw`w-full md:w-[50vw] lg:w-[38vw]`}
`

export const backgroundPatternStyles = css`
  &::before {
    content: '';
    ${tw`absolute block w-full h-full bg-no-repeat bg-cover bg-center`}
    `

const bgPatterns = {
  branches: css`
    &::before {
      ${BranchesCSS}
    }
  `,
  laurel: css`
    &::before {
      ${LaurelCSS}
    }
  `,
  angles: css`
    &::before {
      ${AnglesCSS}
    }
  `,
  gear: css`
    &::before {
      ${GearCSS}
    }
  `,
  arch: css`
    &::before {
      ${ArchCSS}
    }
  `,
}

export const getBgPattern = (pattern: TPatternVariants) => {
  return bgPatterns[pattern]
}
