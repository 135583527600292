import { useEffect, useState } from 'react'

export function useScrollDirection() {
  const [isHidden, setIsHidden] = useState(false)
  const [isScrollTop, setIsScrollTop] = useState(true)

  useEffect(() => {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const goingDown = scrollY > lastScrollY
      const diff = 4
      const scrollDiff = scrollY - lastScrollY

      setIsScrollTop(scrollY === 0)

      if (Math.abs(scrollDiff) > diff && goingDown !== isHidden) {
        setIsHidden(goingDown)
      }

      lastScrollY = Math.max(scrollY, 0)
    }

    window.addEventListener('scroll', updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  })

  return {
    isHidden,
    isScrollTop,
  }
}
