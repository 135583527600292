import isEqual from 'lodash.isequal'
import React, { memo } from 'react'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m10 19-7-7m0 0 7-7m-7 7h18" stroke="currentColor" strokeLinecap="square" />
  </svg>
)

const ArrowLeft = memo(SvgComponent, isEqual)
export default ArrowLeft
