/* eslint-disable react/display-name */
import type { TButtonProps } from '@okam/shared-ui'
import { Button, ButtonWithForwardRef, useTheme } from '@okam/shared-ui'
import React from 'react'

export const BrandButtonWithForwardRef = React.forwardRef(
  (props: TButtonProps, forwardRef: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
    const { variant, children, buttonStyle, disabled, iconPosition } = props
    const ref = forwardRef
    const { brandTheme } = useTheme()
    return (
      <ButtonWithForwardRef
        ref={ref}
        {...props}
        css={brandTheme.button({ variant, buttonStyle, disabled, iconPosition })}
      >
        {children}
      </ButtonWithForwardRef>
    )
  },
)

const BrandButton = (props: TButtonProps) => {
  const { variant, children, buttonStyle, disabled, iconPosition, role } = props
  const { brandTheme } = useTheme()

  return (
    <Button
      role={role ?? 'button'}
      {...props}
      css={brandTheme.button({ variant, buttonStyle, disabled, iconPosition })}
    >
      {children}
    </Button>
  )
}
export default BrandButton
