/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { extractRelativePath } from '@okam/html2react'
import { useTheme } from '@okam/shared-ui'
import FuzzySearch from '@okam/shared-ui/src/components/fields/FuzzySearch/FuzzySearch'
import type {
  RenderProp,
  TFuzzySearchProps,
} from '@okam/shared-ui/src/components/fields/FuzzySearch/FuzzySearch.interface'
import Link from 'next/link'
import { useState } from 'react'
import tw from 'twin.macro'
import Paragraph from '../../Paragraph'

const BrandSearchInput = (props: TFuzzySearchProps<unknown>) => {
  const { isDisabled, dataFuzzySearch, icon, handleResultPress } = props
  const { brandTheme } = useTheme()
  const [searchValue, setSearchValue] = useState('')

  const handleResultSelection = () => {
    if (handleResultPress != null) {
      handleResultPress()
    }
    setSearchValue('')
  }
  const renderResults: RenderProp<unknown> = (results) => (
    <div tw="relative w-full">
      <ul tw="absolute w-full mt-3 overflow-y-auto bg-white rounded-md shadow-sm max-h-64">
        {results.map((item: any) => (
          <Link href={extractRelativePath(item.url ?? '')} key={item.id} onClick={handleResultSelection}>
            <li tw="cursor-pointer flex items-center justify-between gap-2 px-3 py-2 duration-150 text-gray-500 hover:text-white hover:bg-tertiary-6">
              <Paragraph tw="flex-1 text-left flex items-center gap-x-1" variant="footnote" as="p">
                {item.title}
              </Paragraph>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )

  return (
    <FuzzySearch
      {...props}
      dataFuzzySearch={dataFuzzySearch}
      optionsFuzzySearch={{
        keys: ['title'],
        threshold: 0.4,
      }}
      render={renderResults}
      icon={icon}
      css={brandTheme.searchInput({ isDisabled })}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  )
}

export default BrandSearchInput
