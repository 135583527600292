import React from 'react'
import SearchInput from '../SearchInput/SearchInput'
import type { TFuzzySearchProps } from './FuzzySearch.interface'
import { useFuzzySearch } from './useFuzzySearch'

const FuzzySearch = (props: TFuzzySearchProps<unknown>) => {
  const {
    inputProps,
    onChange,
    optionsFuzzySearch = {},
    dataFuzzySearch = [],
    searchValue = '',
    setSearchValue,
    ...rest
  } = props
  const { searchResults, fuzzySearchQuery } = useFuzzySearch(dataFuzzySearch, optionsFuzzySearch)
  const { render: renderResults } = props
  const results = renderResults && renderResults([...searchResults])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchValue != null) {
      setSearchValue(event.target.value)
    }
    onChange?.(event)
    fuzzySearchQuery(searchValue)
  }

  return (
    <div tw="w-full">
      <SearchInput {...rest} {...inputProps} onChange={handleChange} value={searchValue} />
      {searchValue && results}
    </div>
  )
}

export default FuzzySearch
