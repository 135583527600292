import request from 'graphql-request'
import getWpUrlFromLang from '../../backend/wp'
import { graphql } from '../../gql'

export const siteOptions = graphql(/* GraphQL */ `
  query siteOptions {
    siteOptions {
      siteOptions {
        fieldGroupName
        siteAddressInfo
        siteContactInfo
        siteLinkedin
        sitePrivacyPolicy
        siteWhiteLogo {
          sourceUrl(size: _2048X2048)
          altText
          description
          id
          mediaDetails {
            height
            width
          }
        }
        siteLogo {
          sourceUrl(size: _2048X2048)
          altText
          description
          id
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`)

async function querySiteOptions(language?: string) {
  const wpUrl = getWpUrlFromLang(language)
  const data = await request(wpUrl, siteOptions)

  return data
}

export default querySiteOptions
