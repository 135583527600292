import tw from 'twin.macro'
import MenuItems from '../MenuItems'
import type { MenuPanelProps } from './interface'
import { StyledMenu, backgroundPatternStyles, getBgPattern } from './styles'

export const MenuPanel = (props: MenuPanelProps) => {
  const {
    bgPattern,
    beforeMenu,
    afterMenu,
    menuItems,
    labelBackButton,
    openBurgerMenu,
    isSecondaryPanelOpen,
    setIsSecondaryPanelOpen,
    setOpenBurgerMenu,
  } = props
  const style = {
    container: [
      tw`w-[100vw] h-[100vh] absolute top-0 left-0 bottom-0 transition duration-300 pointer-events-none`,
      openBurgerMenu ? tw`bg-black/50` : tw`bg-black/0`,
    ],
  }

  return (
    <div css={style.container}>
      <StyledMenu
        openBurgerMenu={openBurgerMenu}
        css={[
          bgPattern && getBgPattern(bgPattern),
          backgroundPatternStyles,
          tw`pointer-events-auto overflow-y-scroll overflow-x-hidden h-full flex-col justify-start pt-[12vh] lg:min-w-[500px]`,
        ]}
      >
        <div tw="w-full h-auto flex flex-col justify-start z-10 lg:pl-12 md:justify-center ">
          {beforeMenu}
          <MenuItems
            isSecondaryPanelOpen={isSecondaryPanelOpen}
            bgPattern={bgPattern}
            labelBackButton={labelBackButton}
            menuItems={menuItems}
            setIsSecondaryPanelOpen={setIsSecondaryPanelOpen}
            openBurgerMenu={openBurgerMenu}
            setOpenBurgerMenu={setOpenBurgerMenu}
          />
          {afterMenu}
        </div>
      </StyledMenu>
    </div>
  )
}
