import tw, { css } from 'twin.macro'

export const FooterLinksStyle = css`
  ${tw`text-white`}
`

export const baseStyle = css`
  ${tw` text-white bg-tertiary-6`}
  div > span {
    ${tw`font-semibold`}
  }
  a[href^='mailto:'] {
    ${tw`italic`}
  }
`
