import React from 'react'
import BrandButton from '../../BrandButton/index'
import type { TFooterLinksProps } from './interface'

const FooterLinks = (props: TFooterLinksProps) => {
  const { children, href, ...rest } = props

  return (
    <BrandButton
      {...rest}
      href={href ?? '/'}
      as="a"
      buttonStyle="hollow"
      variant="white"
      tw="!px-0 !min-w-fit !min-h-fit"
    >
      {children}
    </BrandButton>
  )
}

export default FooterLinks
