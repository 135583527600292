import { ButtonWithForwardRef } from '@okam/shared-ui/src'
import tw, { styled, css } from 'twin.macro'

export const StyledMenuItems = styled.ul(() => tw`flex flex-col items-start`)

export const StyledMenuList = styled.li(() => tw`flex items-center justify-center`)

export const StyledMenuButton = styled(ButtonWithForwardRef)(
  () => css`
    ${tw`[font-size: 14px] text-white !bg-transparent !pl-0`}
    span, a {
      ${tw`h-full w-full`}
    }
    a {
      ${tw`flex items-center`}
    }
  `,
)
