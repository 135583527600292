import tw, { css, styled } from 'twin.macro'
import type { TStyledInputProps } from './SearchInput.interface'

export const getIconVariant = (isDisabled = false, isError = false) => [isError && tw`text-red-600`, isDisabled && tw``]

export const disableWKStyles = css`
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`

export const StyledInput = styled.input(({ isError = false }: TStyledInputProps) => [
  tw`transition duration-300 ease-in-out w-full px-6 py-4 lg:px-8 lg:py-5 rounded-full border-2 focus:!outline-none`,
  isError && tw`border-red-600`,
])
