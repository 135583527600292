import 'twin.macro'
import { Footer, FooterLinks, FooterLinksContainer } from '@okam/brand-ui/index'
import { extractRelativePath } from '@okam/html2react/index'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import type { ReactNode } from 'react'
import type { SiteOptionsQuery, PostTypeSeo, MainMenuFragment, AllMembersFragment, Maybe } from 'gql/graphql'
import { LayoutHeader } from './LayoutHeader'

type TSiteOptionsProps = Pick<SiteOptionsQuery, 'siteOptions'>['siteOptions']

export interface TMainLayout {
  children: ReactNode
  siteOptionsProps: TSiteOptionsProps
  seo: PostTypeSeo | undefined | null
  menuItems?: MainMenuFragment
  variant?: 'white' | 'colored' | 'half'
  translatedUri?: string | null
  allMembers?: Maybe<AllMembersFragment>
}

export const MainLayout = (props: TMainLayout) => {
  const { children, siteOptionsProps, seo, menuItems, translatedUri, variant = 'white', allMembers } = props
  const siteProps = siteOptionsProps?.siteOptions
  const { t } = useTranslation('common')
  const contactTitle = t('FOOTER.CONTACT')
  const addressTitle = t('FOOTER.ADDRESS')
  const homeLinkAriaLabel = t('LINKS.ARIA_HOMEPAGE')
  const homeUri = t('ROUTES.HOME') ?? '/'
  const languageDefaultSwitcherUri = t('LANGUAGESWITCHER.URI') ?? '/'
  const languageSwitcherLabel = t('LANGUAGESWITCHER.LOCALE') ?? ''
  const privacyPolicyTitle = t('FOOTER.PRIVACY_POLICY')

  const isNoFollowFalse = seo?.metaRobotsNofollow === 'nofollow'
  const isNoIndexFalse = seo?.metaRobotsNoindex === 'noindex'

  const languageSwitcherUri = translatedUri ? extractRelativePath(translatedUri) : languageDefaultSwitcherUri

  return (
    <div tw="flex flex-col w-screen h-full justify-between min-h-screen">
      <NextSeo
        title={seo?.title ?? 'Page'}
        description={seo?.metaDesc ?? ''}
        openGraph={{
          title: `${seo?.opengraphTitle ?? ''}`,
          type: `${seo?.opengraphType ?? ''}`,
          siteName: `${seo?.opengraphSiteName ?? ''}`,
          description: `${seo?.opengraphDescription ?? ''}`,
          url: `${seo?.opengraphUrl ?? ''}`,
          images: [
            {
              url: `${seo?.opengraphImage?.sourceUrl ?? ''}`,
              width: seo?.opengraphImage?.mediaDetails?.width ?? 0,
              height: seo?.opengraphImage?.mediaDetails?.height ?? 0,
              alt: `${seo?.opengraphImage?.altText}`,
              type: `${seo?.opengraphImage?.mediaType ?? ''}`,
            },
          ],
        }}
        nofollow={isNoFollowFalse}
        noindex={isNoIndexFalse}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: `${seo?.metaKeywords ?? ''}`,
          },
        ]}
      />

      <LayoutHeader
        menuItems={menuItems}
        variant={variant}
        siteOptionsProps={siteOptionsProps}
        languageSwitcherUri={languageSwitcherUri}
        allMembers={allMembers}
      />

      <main tw="relative h-full grow">{children}</main>

      <Footer
        logo={siteProps?.siteWhiteLogo?.sourceUrl ?? ''}
        logoAlt={siteProps?.siteWhiteLogo?.altText ?? ''}
        homeLink={homeUri}
        homeLinkAriaLabel={homeLinkAriaLabel}
        contactTitle={contactTitle}
        contact={siteProps?.siteContactInfo ?? ''}
        addressTitle={addressTitle}
        address={siteProps?.siteAddressInfo ?? ''}
        privacyPolicyTitle={privacyPolicyTitle ?? ''}
        privacyPolicyLink={siteProps?.sitePrivacyPolicy ? extractRelativePath(siteProps.sitePrivacyPolicy) : ''}
      >
        <FooterLinksContainer>
          <FooterLinks href={languageSwitcherUri}>{languageSwitcherLabel}</FooterLinks>
          {siteProps?.siteLinkedin && (
            <FooterLinks target="_blank" rel="noopener noreferrer" href={siteProps?.siteLinkedin}>
              LinkedIn
            </FooterLinks>
          )}
        </FooterLinksContainer>
      </Footer>
    </div>
  )
}
