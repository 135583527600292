// import { WysiwygBlock } from '@okam/shared-ui'
import { Cover, Inline } from '@bedrock-layout/primitives'
import Link from 'next/link'
import React from 'react'
import tw, { styled } from 'twin.macro'
import { IMAGE_SMALL_WIDTH_HEIGHT } from '../../const'
import Heading from '../Heading'
import Img from '../Img'
import Paragraph from '../Paragraph'
import WysiwygBlock from '../WysiwygBlock'
import type { TFooterProps } from './interface'
import { baseStyle } from './styles'

const StyledFooter = styled(Cover)(() => [baseStyle])

const StyledBottomLink = styled(Link)(() => [
  tw`font-body text-footnote block transition duration-300 ease-in-out hover:text-gray-400 md:mr-4 lg:mr-24`,
])

const StyledCopyrightText = styled.span(() => [tw`mr-1 mb-2`])

const Copyright = () => {
  const year = new Date().getFullYear()

  return (
    <Paragraph tw="flex flex-row flex-wrap justify-start items-end italic !font-light" variant="footnote">
      <StyledCopyrightText>© Woods s.e.n.c.r.l</StyledCopyrightText>
      <StyledCopyrightText>{year}</StyledCopyrightText>
    </Paragraph>
  )
}

const Footer = (props: TFooterProps) => {
  const {
    children,
    logo,
    logoAlt,
    homeLinkAriaLabel,
    address,
    addressTitle,
    contact,
    contactTitle,
    privacyPolicyTitle,
    privacyPolicyLink,
    homeLink,
    isFooterPanel,
    ...rest
  } = props

  return (
    <StyledFooter tw="pb-6 pt-24 md:pt-16" {...rest} minHeight="fit-content">
      <Inline
        tw="max-w-[1920px] mx-auto !grid grid-cols-1 relative md:grid-cols-12 px-6 md:px-12 lg:px-14"
        justify="center"
        stretch="all"
        css={[isFooterPanel && tw`md:grid-cols-4 px-0 md:px-0 lg:px-0`]}
      >
        <div tw="flex flex-col items-start gap-4 md:h-full md:content-between md:grid md:col-span-4 xl:col-span-6">
          <div tw="flex items-center h-full">
            {logo && (
              <Link
                href={homeLink ?? ''}
                passHref
                tw="relative mb-2 w-[180px] h-[54px] md:w-[140px] md:h-[42px] lg:w-[180px] lg:h-[54px] xl:w-[200px] xl:h-[60px]"
                aria-label={homeLinkAriaLabel ?? ''}
              >
                <Img
                  src={logo}
                  alt={logoAlt ?? ''}
                  width={IMAGE_SMALL_WIDTH_HEIGHT}
                  height={IMAGE_SMALL_WIDTH_HEIGHT}
                />
              </Link>
            )}
          </div>
          {children && children}
        </div>
        <div tw="mt-8 md:mt-0 md:col-span-8 xl:col-span-6 md:pl-4" css={[isFooterPanel && tw`md:pl-0 md:mt-8`]}>
          <div tw="lg:grid lg:grid-cols-2 ">
            <div tw="mt-0 md:mt-20" css={[isFooterPanel && tw`md:mt-0`]}>
              {contact && (
                <div tw="mb-6">
                  {contactTitle && (
                    <Heading as="p" variant="h6" tw="!font-semibold mb-1">
                      {contactTitle}
                    </Heading>
                  )}
                  <WysiwygBlock content={contact} variant="footnote" />
                </div>
              )}
            </div>
            <div tw="mt-0 md:mt-20" css={[isFooterPanel && tw`md:mt-0`]}>
              {address && (
                <div tw="mb-6">
                  {addressTitle && (
                    <Heading as="p" variant="h6" tw="!font-semibold mb-1">
                      {addressTitle}
                    </Heading>
                  )}
                  <WysiwygBlock content={address} variant="footnote" />
                </div>
              )}
            </div>
          </div>
          <div tw="mt-0 md:mt-8 md:grid md:grid-cols-2" css={[isFooterPanel && tw`md:flex flex-col`]}>
            {privacyPolicyLink && <StyledBottomLink href={privacyPolicyLink}>{privacyPolicyTitle}</StyledBottomLink>}
            <Copyright />
          </div>
        </div>
      </Inline>
    </StyledFooter>
  )
}

export default Footer
