import { extractRelativePath } from '@okam/html2react'
import Link from 'next/link'
import React from 'react'
import tw from 'twin.macro'
import ArrowLeft from '../../icons/ArrowLeft'
import ChevronRight from '../../icons/ChevronRight'
import Paragraph from '../../Paragraph'
import { StyledMenu, backgroundPatternStyles, getBgPattern } from '../MenuPanel/styles'
import type { MenuItem, TItems, TMenuItemsProps } from './interface'
import { StyledMenuItems, StyledMenuList } from './styles'

const MenuItems = (props: TMenuItemsProps) => {
  const {
    menuItems,
    labelBackButton,
    bgPattern,
    isSecondaryPanelOpen,
    setIsSecondaryPanelOpen,
    openBurgerMenu,
    setOpenBurgerMenu,
  } = props

  const handlePanelToggle = () => {
    setIsSecondaryPanelOpen(!isSecondaryPanelOpen)
  }

  const handleMenuOverflow = () => {
    setIsSecondaryPanelOpen(false)
    setOpenBurgerMenu(!openBurgerMenu)
    document.body.style.overflow = 'auto'
  }

  const getRelativePath = (urlString: string) => {
    try {
      return extractRelativePath(urlString)
    } catch (e) {
      return urlString
    }
  }

  return (
    <div tw="mt-20 md:pl-12 lg:pl-0">
      <StyledMenuItems tw="gap-6">
        {menuItems?.edges?.map((item: TItems) => {
          if (item?.node.path == null || item.node.id == null) {
            return null
          }
          const isSubMenu = () => {
            if (item?.node?.childItems && item?.node?.childItems?.nodes?.length > 0) {
              return true
            }
            return false
          }

          return (
            <StyledMenuList key={`li-${item.node.id}`}>
              {isSubMenu() ? (
                <button
                  css={tw`text-white hover:text-[#9A9CB7] transition-all uppercase text-2xl !bg-transparent !pl-0 flex items-center gap-5 font-body`}
                  type="button"
                  onClick={handlePanelToggle}
                >
                  {item.node.label}
                  <ChevronRight />
                </button>
              ) : (
                <Link
                  target={item.node.target ?? '_self'}
                  css={tw`text-white hover:text-[#9A9CB7] transition-all uppercase text-2xl !bg-transparent !pl-0 flex items-end gap-5`}
                  href={item.node.target !== '_blank' ? getRelativePath(item.node.path) : item.node.path}
                  rel="noopener noreferrer"
                  onClick={() => handleMenuOverflow()}
                >
                  {item.node.label}
                </Link>
              )}
            </StyledMenuList>
          )
        })}
      </StyledMenuItems>
      {isSecondaryPanelOpen && (
        <StyledMenu
          css={[bgPattern && getBgPattern(bgPattern), backgroundPatternStyles]}
          openBurgerMenu={isSecondaryPanelOpen}
        >
          <div tw="z-20 h-full mt-8 md:mt-10 md:pl-12 lg:h-4/5 lg:mt-0">
            <div>
              <button
                type="button"
                onClick={handlePanelToggle}
                tw="text-white hover:text-[#9A9CB7] transition-all uppercase flex mt-8"
              >
                <ArrowLeft />
                <Paragraph tw="ml-2">{labelBackButton}</Paragraph>
              </button>
            </div>
            <div tw="w-full h-full flex flex-col mt-8 md:mt-20 gap-6">
              {menuItems?.edges?.map((item: TItems) => {
                return (
                  item.node.childItems &&
                  item.node.childItems.nodes.map((childItem: MenuItem) => {
                    const childPath =
                      childItem?.target !== '_blank' ? getRelativePath(childItem?.path ?? '') : childItem?.path
                    return (
                      <Link
                        key={childItem.id}
                        href={childPath ?? ''}
                        target={childItem.target ?? '_self'}
                        rel="noopener noreferrer"
                        css={tw`text-white hover:text-[#9A9CB7] transition-all uppercase text-2xl !bg-transparent !pl-0 flex items-end gap-5`}
                        onClick={() => handleMenuOverflow()}
                      >
                        {childItem.label}
                      </Link>
                    )
                  })
                )
              })}
            </div>
          </div>
        </StyledMenu>
      )}
    </div>
  )
}

export default MenuItems
