import Link from 'next/link'
import tw from 'twin.macro'
import type { ILanguageSwitcherProps } from './interface'

const LanguageSwitcher = (props: ILanguageSwitcherProps) => {
  const { variant, ariaLabel, label, uri } = props

  return (
    <Link aria-label={ariaLabel ?? ''} href={uri ?? ''} locale={false}>
      <span
        tw="font-body"
        css={[variant === 'colored' ? tw`text-tertiary-5` : tw`text-tertiary-2 4xl:text-tertiary-5`]}
      >
        {label}
      </span>
    </Link>
  )
}

export default LanguageSwitcher
