import { useSearchField } from '@react-aria/searchfield'
import { useSearchFieldState } from '@react-stately/searchfield'
import { useRef } from 'react'
import { FocusRing, mergeProps, useFocusRing } from 'react-aria'
import tw from 'twin.macro'
import type { TSearchInputProps } from './SearchInput.interface'
import { disableWKStyles, getIconVariant, StyledInput } from './SearchInput.styles'

const SearchInput = (props: TSearchInputProps) => {
  const {
    id,
    label,
    'aria-label': ariaLabel,
    name,
    placeholder,
    isDisabled,
    isError,
    errorMessage,
    value,
    onBlur,
    onChange,
    inputRef,
    required = false,
    icon,
  } = props

  const state = useSearchFieldState(props)
  const ref = useRef<HTMLInputElement | null>(null)
  const { labelProps, errorMessageProps, inputProps } = useSearchField(props, state, ref)
  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <div tw="w-full">
      {label && (
        <label htmlFor={id} {...labelProps}>
          {label}
        </label>
      )}
      <FocusRing {...focusProps} focusRingClass="focus-ring">
        <div css={[tw`grid grid-cols-2 lg:min-w-[317px] lg:w-2/3 grid-rows-1 relative`, disableWKStyles]}>
          <StyledInput
            {...mergeProps(inputProps, props)}
            type="search"
            ref={(e) => {
              inputRef?.(e)
              ref.current = e
            }}
            id={id}
            name={name}
            aria-label={ariaLabel}
            placeholder={placeholder}
            isDisabled={isDisabled}
            required={required}
            isError={isError}
            isFocused={isFocusVisible}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            css={[tw`[-webkit-appearance:none] [-moz-appearance:none] col-start-1 col-end-4 row-start-1`]}
          />
          {state.value === '' && icon && (
            <div
              css={[
                tw`text-inherit col-start-2 col-end-2 row-start-1 absolute right-0`,
                getIconVariant(isDisabled, isError),
              ]}
            >
              {icon}
            </div>
          )}
        </div>
      </FocusRing>
      {isError && errorMessage && (
        <div {...errorMessageProps} tw="text-red-600 text-sm px-2 ml-6 lg:ml-4">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default SearchInput
