import { css } from 'twin.macro'

export const burgerStyles = css`
  .burger {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 20px;
    right: 20px;
    border-radius: 4px;
    z-index: 10;

    span {
      @include burger(30px, 3px, 6px, $gray); // gray
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -1.5px;
    }

    &.clicked {
      span {
        @include burger-to-cross;

        &:before,
        &:after {
          background-color: $white;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`
export const logoAlign = {
  left: {
    logo: 'order-1 justify-self-start',
    languageSwitcher: 'order-2 text-right',
    burgerIcon: 'order-3 justify-self-end',
  },
  right: {
    logo: 'order-3 justify-self-end',
    languageSwitcher: 'order-2 text-left',
    burgerIcon: 'order-1',
  },
  centerWithBurgerAtLeft: {
    logo: 'order-3 justify-self-center',
    languageSwitcher: 'order-2 text-left',
    burgerIcon: 'order-1 justify-self-start',
  },
  centerWithBurgerAtRight: {
    logo: 'order-1 justify-self-center',
    languageSwitcher: 'order-2 text-right',
    burgerIcon: 'order-3 justify-self-end',
  },
}
