/**
 * Use environment variable to get graphql url
 * @param {string|undefined} locale 'en' will use GRAPHQL_PATH_EN instead of GRAPHQL
 * @returns
 */
export function getGraphqlUrl(locale = ''): string {
  const path =
    (locale === 'en' && process.env.NEXT_GRAPHQL_PATH_EN
      ? process.env.NEXT_GRAPHQL_PATH_EN
      : process.env.NEXT_GRAPHQL_PATH) ?? ''
  return `${process.env.NEXT_PUBLIC_BACKEND}${path}`
}

export function getRestUrl(): string {
  const path = process.env.NEXT_PUBLIC_REST_PATH ?? ''
  return `${process.env.NEXT_PUBLIC_BACKEND}${path}`
}

export function getFormContact(): string {
  return String(process.env.NEXT_PUBLIC_FORM_CONTACT_ID ?? '')
}

const config = {
  getGraphqlUrl,
  getRestUrl,
  getFormContact,
}

export default config
