import isEqual from 'lodash.isequal'
import React, { memo } from 'react'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m9 5 7 7-7 7" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
  </svg>
)

const ChevronRight = memo(SvgComponent, isEqual)
export default ChevronRight
